import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MenuPreventDirective } from "./directives/menu-prevent.directive";
@NgModule({
  exports: [MatButtonModule, MatIconModule, MatMenuModule],
  providers: [MenuPreventDirective],
})
export class BasicModule {}
